import React, { Component } from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import Header from "../components/Header/Header"
import Footer from "../components/Footer"

import Container from "react-bootstrap/Container"

import PageTitle from "../components/PageTitle"

const StyledContainer = styled(Container)`
  margin-bottom: 30px;
  padding-left: 0px !important;
  padding-right: 0px !important;
`

const StyledH4 = styled.h4`
    margin-top: 15px;
  margin-bottom: 25px;
`

const StyledP = styled.p`
  font-size: 1.05em;
`

const StyledLi = styled.li`
    margin-left: 20px;
    font-size: 1.05em;
`

export class Privacy extends Component {
  render() {
    return (
      <div>
        <Layout />
        <Header />
          <StyledContainer fluid>
            <PageTitle link="/privacy" title="M8 Privacy Policy" />
            <div className="container">
                <StyledH4>Privacy</StyledH4>
                    <StyledP>
                        Privacy is one of M8 System, Inc.’s (“M8 Systems”) core values. 
                        Your AIoT irrigation device is important to many aspects of your business. 
                        Our aim is to keep your business your business.
                    </StyledP>
                <StyledH4>
                    Collection and Use of Data
                </StyledH4>
                    <StyledP>
                        Data referred to under this policy can be used to identify or contact 
                        a user of an M8 SYSTEMS Device.You may be asked to provide information anytime 
                        you are in contact with M8 SYSTEMS or an M8 SYSTEMS affiliated company. 
                        M8 SYSTEMS and its affiliates may share this information with each other and use it 
                        consistent with this Privacy Policy. They may also combine it with other information
                        to provide and improve our products and services. You are not required to provide 
                        the information that we have requested, but, if you chose not to do so, 
                        in some cases we will not be able to provide you with our products or services 
                        or respond to any queries you may have.
                    </StyledP>

                <StyledP>
                    Here are some examples of the types of information M8 SYSTEMS may collect and how we may use it:
                </StyledP>

                <StyledH4>
                    What information we collect
                </StyledH4>
                <StyledP>
                        When you download a software update, connect to our services, contact us or participate in an online survey, we may collect a variety of information, including your organization’s name, mailing address, phone number, email address, contact preferences, device identifiers, IP address, location information, and credit card information, if applicable.
                </StyledP>

                <StyledH4>
                    How we use your information
                </StyledH4>
                <StyledP>
                    We may process your information: for the purposes described in this Privacy Policy, with your consent, for compliance with a legal obligation to which M8 SYSTEMS is subject, for the performance of a contract to which you are party, in order to protect your vital interests, or when we have assessed it is necessary for the purposes of the legitimate interests pursued by M8 SYSTEMS or a third party to whom it may be necessary to disclose information. If you have questions about this legal basis you can contact the Data Protection Officer.
                </StyledP>
                <ul>
                    <StyledLi>
                        The data information we collect allows us to keep you posted on M8 SYSTEMS’s latest product announcements, software updates, and upcoming events. If you don’t want to be on our mailing list, you can opt-out anytime by updating your preferences.
                    </StyledLi>
                    <StyledLi>
                        We also use data information to help us create, develop, operate, deliver, and improve our products, services, content and advertising. We may also use your information for account and network security purposes, including in order to protect our services for the benefit of all our users.
                    </StyledLi>
                    <StyledLi>
                        From time to time, we may use your data information to send important notices, such as communications about changes to our terms, conditions, and policies. Because this information is important to your interaction with M8 SYSTEMS, you may not opt out of receiving these communications.
                    </StyledLi>
                    <StyledLi>
                        We may also use data information for internal purposes such as auditing, data analysis, and research to improve M8 SYSTEMS’s products, services, and customer communications.
                    </StyledLi>
                </ul>

                <StyledH4>
                    Source of your information where they are not collected from you
                </StyledH4>
                <StyledP>
                    We may validate the information provided by you when creating an M8 SYSTEMS ID when applicable with a third party for security and fraud prevention purposes.
                </StyledP>

                <StyledH4>
                    Collection and Use of Information
                </StyledH4>
                <StyledP>
                    We also collect data in a form that does not, on its own, permit direct association with any specific organization. We may collect, use, transfer, and disclose information for any purpose. The following are some examples of information that we collect and how we may use it:
                </StyledP>
                <ul>
                    <StyledLi>
                        We may collect information such as industry, zip code, area code, unique device identifier, referrer URL, location, and the time zone where an M8 SYSTEMS product or device is used so that we can better understand customer behavior and improve our products, services, and advertising.
                    </StyledLi>
                    <StyledLi>
                        We may collect information regarding customer activities on our website, cloud services, and from our other products and services. This information is aggregated and used to help us provide more useful information to our customers and to understand which parts of our website, products, and services are of most interest.
                    </StyledLi>
                    <StyledLi>
                        We may collect and store details of how you use our software and services, including search queries. This information may be used to improve the relevancy of results provided by our services. Except in limited instances to ensure quality of our services over the Internet, such information will not be associated with your IP address.
                    </StyledLi>
                    <StyledLi>
                        With your explicit consent, we may collect data about how you use your device and applications in order to help app developers improve their apps.
                    </StyledLi>
                </ul>

                <StyledH4>
                    Cookies and Other Technologies
                </StyledH4>
                <StyledP>
                    M8 SYSTEMS’s websites, online services, interactive applications, email messages, and advertisements may use "cookies" and other technologies such as pixel tags and web beacons. These technologies help us better understand user behavior, tell us which parts of our websites people have visited, and facilitate and measure the effectiveness of advertisements and web searches. We treat information collected by cookies and other technologies as non-specific identifier information.
                </StyledP>
                <StyledP>
                    As is true of most internet services, we gather some information automatically and store it in log files. This information includes Internet Protocol (IP) addresses, browser type and language, Internet service provider (ISP), referring and exit websites and applications, operating system, date/time stamp, and clickstream data. We use this information to understand and analyze trends, to administer the site, to learn about user behavior on the site, to improve our product and services. M8 SYSTEMS may use this information in our marketing and advertising services.
                </StyledP>
            
                <StyledH4>
                    Others
                </StyledH4>
                <StyledP>
                    It may be necessary − by law, legal process, litigation, and/or requests from public and governmental authorities within or outside your country of residence − for M8 SYSTEMS to disclose your information. We may also disclose information about you if we determine that for purposes of national security, law enforcement, or other issues of public importance, disclosure is necessary or appropriate. We may also disclose information about you, but only where there is a lawful basis for doing so, if we determine that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations or users. This could include providing information to public or governmental authorities. Additionally, in the event of a reorganization, merger, or sale we may transfer any and all information we collect to the relevant third party.
                </StyledP>

                <StyledH4>
                    Protection of Information
                </StyledH4>
                <StyledP>
                    M8 SYSTEMS takes the security of your information very seriously. M8 SYSTEMS online services protect your information during transit using encryption. When your data is stored by M8 SYSTEMS, we use computer systems with limited access housed in facilities using security measures. Cloud data is stored in encrypted form including when we utilize third-party storage.
                </StyledP>

                <StyledH4>
                    Retention of Information
                </StyledH4>
                <StyledP>
                    We will retain your information for the period necessary to fulfill the purposes outlined in this Privacy Policy and our service specific privacy summaries. When assessing these periods we carefully examine our need to collect information at all and if we establish a relevant need we only retain it for the shortest possible period to realize the purpose of collection unless a longer retention period is required by law.
                </StyledP>
            
                <StyledH4>
                    Location-Based Services
                </StyledH4>
                <StyledP>
                    To provide location-based services on M8 SYSTEMS products, M8 SYSTEMS and our partners and licensees, such as maps data providers, may collect, use, and share precise location data, including the real-time geographic location of your M8 SYSTEMS device. Where available, location-based services may use GPS, Bluetooth, and your IP Address, along with crowd-sourced Wi-Fi hotspot and cell tower locations, and other technologies to determine your devices’ approximate location. Unless you provide consent, this location data is collected anonymously in a form that does not uniquely identify you and is used by M8 SYSTEMS and our partners and licensees to provide and improve location-based products and services. For example, your device may share its geographic location with application providers when you opt in to their location services.
                </StyledP>
            
                <StyledH4>
                    Third-Party Sites and Services
                </StyledH4>
                <StyledP>
                    M8 SYSTEMS websites, products, applications, and services may contain links to third-party websites, products, and services. Our products and services may also use or offer products or services from third parties − for example, a third-party app.
                </StyledP>
                <StyledP>
                    Information collected by third parties, which may include such things as location data or contact details, is governed by their privacy practices. We encourage you to learn about the privacy practices of those third parties. If you purchase a subscription in a third party app, we may offer the ability to create a Subscriber ID that is unique to you and the developer or publisher which we use to provide reports to the developer or publisher that include information about the subscription you purchased, and your country of domicile.
                </StyledP>

                <StyledH4>
                    Our Companywide Commitment to Your Privacy
                </StyledH4>
                <StyledP>
                    To make sure your information is secure, we communicate our privacy and security guidelines to M8 SYSTEMS employees and strictly enforce privacy safeguards within the company.
                </StyledP>
            
                <StyledH4>
                    Privacy Questions
                </StyledH4>
                <StyledP>
                    If you have any questions or concerns about M8 SYSTEMS’s Privacy Policy or data processing, if you would like to contact our Data Protection Officer, or if you would like to make a complaint about a possible breach of local privacy laws, please contact us. You can always contact us by phone at the relevant M8 SYSTEMS Support number for your country or region.
                </StyledP>
                <StyledP>
                    When a privacy question or question about information received in response to an access/download request is received we have a dedicated team which triages your contact to address your issue. Where your issue may be more substantive in nature, we may request more information from you. All such substantive contacts receive a response within seven (7), days wherever possible - providing a response on the issue raised, requesting additional information where necessary or indicating that a response will require additional time.
                </StyledP>
                <StyledP>
                    Where your complaint indicates an improvement could be made in our handling of privacy issues we will take steps to make such an update at the next reasonable opportunity. In the event that a privacy issue has resulted in a negative impact on your organization then we will take steps to address that with you.
                </StyledP>
                <StyledP>
                    M8 SYSTEMS may update its Privacy Policy from time to time. When we change the policy in a material way, a notice will be posted on our website along with the updated Privacy Policy. We will also contact you via your contact information on file, for example by email, notification or some other equivalent method.   
                </StyledP>
           
           </div>
          </StyledContainer>
        <Footer />
      </div>
    )
  }
}
export default Privacy 